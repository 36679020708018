//variables
//var variable = $(".element").funkce();
var navbarHeight = 100;
var navbarLink = $(".navbar-nav li a");
//onLoad
$(document).ready(function() {
    $(".fancybox").fancybox();
    $(".fancybox-thumbs").fancybox({
        prevEffect: 'none',
        nextEffect: 'none',
        openEffect: 'elastic',
        openSpeed: 150,
        closeEffect: 'elastic',
        closeSpeed: 150,
        closeClick: true,
        beforeLoad : function() {
            this.title = $(this.element).find('img').attr('alt');
        },
        helpers: {
            title: {
                type: 'inside'
            },
            thumbs: {
                width: 50,
                height: 50
            }
        }
    });

});

  //scroll
  $(document).ready(function(){
    $('body').scrollspy({ 
      target: '.navbar',
      offset: $('.header').outerHeight(true) + 18
    });
  });

  // Set hash on scrollspy fire
  $(window).on('activate.bs.scrollspy', function () {
    hash = $(".navbar-nav li.active a").attr("href");
    history.replaceState(null, null, hash);	

  });

  navbarLink.click(function(event) {
    event.preventDefault();
    if ($('.navbar-toggle').is(':visible')) {
      $('.navbar-toggle').click();
    }
    hash = $(this).attr('href');
    history.replaceState(undefined, undefined, hash);
    sectionOffset = $(hash).offset().top;
    $('html, body').animate({
      scrollTop: sectionOffset - $('.header').outerHeight(true) + 36
    }, 850);
      if(History.pushState) {
        history.pushState(null, null, hash);
      }
      else {
        location.hash = hash;
      }

  });

//onResize
$( window ).resize(function() {

});